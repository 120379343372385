import './App.css';
import React, { useEffect, useState }  from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { Menu, Settings, LogOut } from 'lucide-react';

import Auth0Callback from './components/auth/Auth0Callback';
import LoginPage from './components/LoginPage';
import AuthHandler from './components/auth/AuthHandler';
import PrivateRoute from './components/auth/PrivateRoute';
import CheckSubscription from './components/subscription/CheckSubscription';
import SubscriptionError from './components/subscription/SubscriptionError';
import SubscriptionRequired from './components/subscription/SubscriptionRequired';
import Onboarding from './components/onboarding/Onboarding';

import Header from './components/Header';
import Footer from './components/Footer';
import Portal from './components/portal/Portal';
import ProfileSettings from './components/portal/ProfileSettings';
import WelcomePage from './components/Welcome';

const login_domain = "login.activatedhuman.earth";
const clientId = "U1BZ16FiHfb5QN1Jbp64JrTa6OwR7HOO";


function App() {
  return (
    <Auth0Provider
      domain={login_domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/auth0-callback`,
        audience: `https://${login_domain}/api/v2/`,
        scope: "openid profile email"
      }}
    >
      <Router>
        <AppContent />
      </Router>
    </Auth0Provider>
  );
}

function AppContent() {
  const { user, isAuthenticated, logout } = useAuth0();
  const posthog = usePostHog();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user && user.sub) {
      posthog?.identify(user.sub, {
        email: user.email,
        name: user.name
      });
    }
  }, [isAuthenticated, user, posthog]);

  return (
    <div className="App font-sans text-gray-900 flex flex-col h-screen overflow-hidden">
      {/* <Header /> */}
      <AuthHandler />
      <div className="flex-1 overflow-hidden relative">
        {isAuthenticated && (
          <div className="absolute top-4 right-4 z-10">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white focus:outline-none"
            >
              <Menu size={24} />
            </button>
            {menuOpen && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a href="/profile-settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                    <Settings className="inline-block mr-2" size={16} />
                    Settings
                  </a>
                  <a href="#" onClick={() => logout({ returnTo: window.location.origin })} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                    <LogOut className="inline-block mr-2" size={16} />
                    Log Out
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
        <Routes>
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/check-subscription" element={<CheckSubscription />} />
          <Route path="/subscription-error" element={<SubscriptionError />} />
          <Route path="/subscription-required" element={<SubscriptionRequired />} />
          <Route path="/onboarding" element={<PrivateRoute component={Onboarding} />} />
          <Route path="/profile-settings" element={<PrivateRoute component={ProfileSettings} />} />
          <Route path="/auth0-callback" element={<Auth0Callback />} />
          <Route path="/" element={<PrivateRoute component={Portal} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;