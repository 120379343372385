import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { Switch } from '../ui/switch';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../ui/select';
import { Clock, Loader2, ChevronDown, ChevronRight } from 'lucide-react';
import OnboardingLogo from './OnboardingLogo';
import apiClient from '../../apiClient';

const CategorySelection = ({ formData, setFormData }) => {
    const [expandedCategories, setExpandedCategories] = useState([]);

    const categories = {
        "CORE LIFE DIMENSIONS": [
            "Personal Growth & Learning",
            "Health & Vitality",
            "Relationships & Community",
            "Career & Purpose",
            "Financial Wellbeing",
            "Creativity & Expression"
        ],
        "RESILIENCE & RECOVERY": [
            "First Responder Support",
            "Healthcare Worker Wellbeing",
            "Trauma Recovery",
            "Stress Management",
            "Building Resilience",
            "Peer Support"
        ],
        "INNER DEVELOPMENT": [
            "Mindfulness & Meditation",
            "Emotional Intelligence",
            "Spiritual Growth",
            "Personal Philosophy",
            "Consciousness Exploration",
            "Shadow Work & Integration"
        ],
        "HEALING & SUPPORT": [
            "PTSD Recovery",
            "Anxiety Management",
            "Self-Care Practices",
            "Professional Support",
            "Family Support Systems",
            "Work-Life Balance"
        ],
        "TRAVEL & ADVENTURE": [
            "World Exploration",
            "Adventure Planning",
            "Cultural Immersion",
            "Digital Nomad Life",
            "Local Discovery",
            "Travel Photography"
        ],
        "LIFESTYLE & PRACTICES": [
            "Morning Routines",
            "Productivity Systems",
            "Life Design",
            "Habit Formation",
            "Digital Minimalism",
            "Sustainable Living"
        ],
        "BODY & WELLNESS": [
            "Movement & Exercise",
            "Nutrition & Diet",
            "Sleep Optimization",
            "Energy Management",
            "Stress Resilience",
            "Biohacking"
        ],
        "RELATIONSHIPS & SOCIAL": [
            "Deep Connections",
            "Communication Skills",
            "Community Building",
            "Leadership",
            "Social Impact",
            "Cultural Understanding"
        ],
        "PROFESSIONAL GROWTH": [
            "Career Development",
            "Entrepreneurship",
            "Creative Projects",
            "Skills Acquisition",
            "Business Strategy",
            "Digital Creation"
        ],
        "PRACTICAL LIFE SKILLS": [
            "Time Management",
            "Decision Making",
            "Financial Literacy",
            "Home Organization",
            "Life Administration",
            "Resource Management"
        ],
        "CREATIVITY & EXPRESSION": [
            "Artistic Pursuits",
            "Writing & Journaling",
            "Music & Sound",
            "Visual Arts",
            "Movement Arts",
            "Creative Process"
        ],
        "TECHNOLOGY & FUTURE": [
            "AI & Technology",
            "Future Trends",
            "Digital Tools",
            "Innovation",
            "Emerging Technologies",
            "System Design"
        ],
        "ENVIRONMENTAL & GLOBAL": [
            "Sustainability",
            "Environmental Action",
            "Global Awareness",
            "Social Justice",
            "Ethical Living",
            "Community Impact"
        ]
    };

    const toggleCategory = (category) => {
        setExpandedCategories(prev =>
            prev.includes(category)
                ? prev.filter(c => c !== category)
                : [...prev, category]
        );
    };

    const toggleSubcategory = (category, subcategory) => {
        setFormData(prev => {
            const currentCategories = prev.selectedCategories || [];
            
            // Find if we already have this category group
            const existingCategoryIndex = currentCategories.findIndex(c => c.category === category);
            
            if (existingCategoryIndex >= 0) {
                // We already have this category, need to update its subcategories
                const updatedCategories = [...currentCategories];
                const currentSubcategories = updatedCategories[existingCategoryIndex].subcategories;
                
                if (currentSubcategories.includes(subcategory)) {
                    // Remove the subcategory
                    updatedCategories[existingCategoryIndex] = {
                        category,
                        subcategories: currentSubcategories.filter(s => s !== subcategory)
                    };
                    
                    // If no subcategories left, remove the whole category
                    if (updatedCategories[existingCategoryIndex].subcategories.length === 0) {
                        updatedCategories.splice(existingCategoryIndex, 1);
                    }
                } else {
                    // Add the subcategory
                    updatedCategories[existingCategoryIndex] = {
                        category,
                        subcategories: [...currentSubcategories, subcategory]
                    };
                }
                
                return {
                    ...prev,
                    selectedCategories: updatedCategories
                };
            } else {
                // This is a new category
                return {
                    ...prev,
                    selectedCategories: [
                        ...currentCategories,
                        {
                            category,
                            subcategories: [subcategory]
                        }
                    ]
                };
            }
        });
    };

    return (
        <div className="space-y-4">
            <div className="mb-4">
                <p className="text-white/90 text-sm mb-2">
                    Select topics that interest you to personalize your experience.
                    We recommend choosing 3-5 areas to start with.
                </p>
                <p className="text-white/70 text-xs">
                    Selected: {formData.selectedCategories?.length || 0} topics
                </p>
            </div>

            <div className="max-h-[400px] overflow-y-auto pr-2 space-y-2">
                {Object.entries(categories).map(([category, subcategories]) => (
                    <div key={category} className="bg-white/5 rounded-lg">
                        <button
                            onClick={() => toggleCategory(category)}
                            className="w-full px-4 py-3 flex items-center justify-between text-left text-white/90 hover:bg-white/10 rounded-lg transition-colors"
                        >
                            <span className="font-medium">{category}</span>
                            {expandedCategories.includes(category) ? (
                                <ChevronDown className="h-5 w-5 text-white/60" />
                            ) : (
                                <ChevronRight className="h-5 w-5 text-white/60" />
                            )}
                        </button>
                        
                        {expandedCategories.includes(category) && (
                            <div className="px-4 pb-3 space-y-2">
                                {subcategories.map(subcategory => (
                                    <label
                                        key={subcategory}
                                        className="flex items-center space-x-3 text-white/80 cursor-pointer hover:text-white/90 transition-colors py-1"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={formData.selectedCategories?.some(c => 
                                                c.category === category && c.subcategories.includes(subcategory)
                                            )}
                                            onChange={() => toggleSubcategory(category, subcategory)}
                                            className="form-checkbox h-4 w-4 text-blue-500 rounded border-white/20 bg-white/5"
                                        />
                                        <span className="text-sm">{subcategory}</span>
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const OnboardingFlow = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [telegramLink, setTelegramLink] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        timezone: '',
        wakeTime: '07:00',
        bedTime: '22:00',
        useIMessage: false,
        useTelegram: false,
        phone: '',
        preferredMessaging: 'imessage',
        acceptedTerms: false,
        telegramConnected: false,
        selectedCategories: []
    });

    // Major US timezones
    const timezoneOptions = [
        { value: 'America/New_York', label: 'Eastern Time (New York)' },
        { value: 'America/Chicago', label: 'Central Time (Chicago)' },
        { value: 'America/Denver', label: 'Mountain Time (Denver)' },
        { value: 'America/Los_Angeles', label: 'Pacific Time (Los Angeles)' },
        { value: 'America/Anchorage', label: 'Alaska Time (Anchorage)' },
        { value: 'Pacific/Honolulu', label: 'Hawaii Time (Honolulu)' }
    ];

    useEffect(() => {
        checkOnboardingStatus();
    }, []);

    const checkOnboardingStatus = async () => {
        setIsLoading(true);
        try {
            const response = await apiClient.get('/portal/onboarding-status');
            if (response.data.isOnboarded) {
                navigate('/welcome');
            } else {
                setTelegramLink(response.data.telegramStartLink);
            }
        } catch (error) {
            console.error('Error checking onboarding status:', error);
        } finally {
            setIsLoading(false);
        }
    };

useEffect(() => {
    if (step === 3) { // Adjust this index based on where your Telegram step is
        verifyTelegramConnection();
        setFormData(prev => ({
            ...prev,
            useTelegram: true,
            preferredMessaging: 'telegram'
        }));
    }
}, [step]);


const verifyTelegramConnection = async () => {
    try {
        const response = await apiClient.get('/portal/verify-telegram');
        if (response.data.isConnected) {
            setFormData(prev => ({ ...prev, telegramConnected: true }));
            setIsPolling(false);
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error verifying Telegram connection:', error);
        return false;
    }
};

const pollTelegramConnection = () => {
    setIsPolling(true);
    let attempts = 0;
    const maxAttempts = 60; // 5 minutes with 5-second intervals

    const pollInterval = setInterval(async () => {
        attempts++;
        const isConnected = await verifyTelegramConnection();
        
        if (isConnected || attempts >= maxAttempts) {
            clearInterval(pollInterval);
            setIsPolling(false);
        }
    }, 5000);

    return pollInterval;
};

const handleTelegramConnect = () => {
    if (telegramLink) {
        window.open(telegramLink, '_blank');
        pollTelegramConnection();
    } else {
        console.error('Telegram link not available');
    }
};

const generateTimeOptions = () => {
    const options = [];
    for (let i = 5; i <= 23; i++) {
        options.push(`${i.toString().padStart(2, '0')}:00`);
        options.push(`${i.toString().padStart(2, '0')}:30`);
    }
    options.push('00:00');
    return options;
};

const timeOptions = generateTimeOptions();

const formatTime = (time) => {
    const [hours, minutes] = time.split(':');
    const date = new Date(2000, 0, 1, hours, minutes);
    return date.toLocaleTimeString([], { 
        hour: 'numeric', 
        minute: '2-digit',
        hour12: true 
    });
};

const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const isValidPhone = (phone) => {
    const digitsOnly = phone.replace(/\D/g, '');
    return digitsOnly.length === 10;
};

const handleIMessageChange = (checked) => {
    setFormData(prev => ({
        ...prev,
        useIMessage: checked,
        phone: checked ? prev.phone : '',
        preferredMessaging: checked ? 'imessage' : prev.useTelegram ? 'telegram' : prev.preferredMessaging
    }));
};

const handleTelegramChange = (checked) => {
    setFormData(prev => ({
        ...prev,
        useTelegram: checked,
        telegramConnected: false,
        preferredMessaging: !prev.useIMessage && checked ? 'telegram' : prev.preferredMessaging
    }));
};

const handleNext = () => {
    if (!isStepValid(step)) {
        return;
    }
    
    if (step < steps.length - 1) {
        setStep(step + 1);
    } else {
        handleSubmit();
    }
};

useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
}, [step, formData]);

const MessagingPreferenceSelector = () => (
    <div className="mt-6 p-4 bg-white/5 rounded-lg">
        <Label className="text-white/90 block mb-3">
            Choose preferred service for outbound messages
        </Label>
        <div className="space-y-3">
            <button
                type="button"
                className={`w-full p-3 rounded-lg flex items-center justify-between ${
                    formData.preferredMessaging === 'imessage' 
                        ? 'bg-white/20 text-white' 
                        : 'bg-white/10 text-white/70'
                }`}
                onClick={() => setFormData({ ...formData, preferredMessaging: 'imessage' })}
            >
                iMessage
                {formData.preferredMessaging === 'imessage' && (
                    <div className="h-2 w-2 rounded-full bg-blue-400" />
                )}
            </button>
            <button
                type="button"
                className={`w-full p-3 rounded-lg flex items-center justify-between ${
                    formData.preferredMessaging === 'telegram' 
                        ? 'bg-white/20 text-white' 
                        : 'bg-white/10 text-white/70'
                }`}
                onClick={() => setFormData({ ...formData, preferredMessaging: 'telegram' })}
            >
                Telegram
                {formData.preferredMessaging === 'telegram' && (
                    <div className="h-2 w-2 rounded-full bg-blue-400" />
                )}
            </button>
        </div>
    </div>
);

const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        if ((step === 3 && e.target.type === 'tel') || step === 5) {
            return;
        }
        if (isStepValid(step)) {
            handleNext();
        }
    }
};

    const isStepValid = (currentStep) => {
        switch (currentStep) {
            case 0: // Name
                return formData.name.trim().length >= 2;
            case 1: // Timezone
                return formData.timezone !== '';
            case 2: // Wake/Bed times
                return formData.wakeTime && formData.bedTime && 
                       formData.wakeTime !== formData.bedTime;
            // case 3: // Messaging
            //     // if (!formData.useIMessage && !formData.useTelegram) {
            //     //     return false;
            //     // }
            //     // if (formData.useIMessage && !isValidPhone(formData.phone)) {
            //     //     return false;
            //     // }
            //     // return true;
            //     return true; // Since we're always enabling Telegram now
            
            case 3: // Telegram connect (if applicable)
                // return formData.useTelegram ? formData.telegramConnected : true;
                return formData.telegramConnected;
            case 4: // Terms
                return formData.acceptedTerms;
            case 5: // Categories
                return (formData.selectedCategories || []).reduce(
                    (total, cat) => total + (cat.subcategories?.length || 0), 
                    0
                ) >= 1;
            default:
                return false;
        }
    };

    const getErrorMessage = (currentStep) => {
        switch (currentStep) {
            case 0:
                if (!formData.name.trim()) return "Please enter your name";
                if (formData.name.trim().length < 2) return "Name must be at least 2 characters";
                return null;
            case 1:
                return formData.timezone === '' ? "Please select your timezone" : null;
            case 2:
                if (!formData.wakeTime || !formData.bedTime) {
                    return "Please select both wake and bed times";
                }
                if (formData.wakeTime === formData.bedTime) {
                    return "Wake and bed times must be different";
                }
                return null;
            // case 3:
            //     if (!formData.useIMessage && !formData.useTelegram) {
            //         return "Please enable at least one messaging method";
            //     }
            //     if (formData.useIMessage && !isValidPhone(formData.phone)) {
            //         return "Please enter a valid phone number";
            //     }
            //     return null;
            
            case 3:
                return formData.useTelegram && !formData.telegramConnected 
                    ? "Please connect your Telegram account to continue" 
                    : null;
            case 4:
                return !formData.acceptedTerms ? "Please accept the terms to continue" : null;
            case 5:
                return formData.selectedCategories?.length === 0 
                    ? "Please select at least one topic of interest" 
                    : null;
            default:
                return null;
        }
    };

    const handleSubmit = async () => {
        try {
            await apiClient.post('/portal/onboarding', {
                name: formData.name.trim(),
                timezone: formData.timezone,
                schedule: {
                    wakeTime: formData.wakeTime,
                    bedTime: formData.bedTime
                },
                messaging: {
                    useIMessage: formData.useIMessage,
                    useTelegram: formData.useTelegram,
                    phone: formData.useIMessage ? formData.phone : null,
                    preferredMethod: formData.preferredMessaging
                },
                interests: formData.selectedCategories,
                acceptedTerms: formData.acceptedTerms
            });
            navigate('/welcome');
        } catch (error) {
            console.error('Error submitting onboarding data:', error);
        }
    };

    const baseSteps = [
        {
            title: "What should we call you?",
            component: (
                <div className="space-y-4">
                    <Input
                        placeholder="Enter your name or nickname"
                        value={formData.name}
                        onKeyDown={handleKeyPress} 
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className={`w-full bg-white/90 border-0 ${
                            formData.name.trim().length > 0 && formData.name.trim().length < 2 
                                ? 'border-2 border-red-500' 
                                : ''
                        }`}
                        autoFocus
                    />
                    {getErrorMessage(0) && (
                        <p className="text-red-400 text-sm">{getErrorMessage(0)}</p>
                    )}
                </div>
            )
        },
        {
            title: "What is your time zone?",
            component: (
                <div className="space-y-4">
                    <Select
                        value={formData.timezone}
                        onValueChange={(value) => setFormData({ ...formData, timezone: value })}
                    >
                        <SelectTrigger className={`w-full bg-white/90 border-0 ${
                            !formData.timezone ? 'text-gray-400' : 'text-black'
                        }`}>
                            <SelectValue placeholder="Select timezone" />
                        </SelectTrigger>
                        <SelectContent>
                            {timezoneOptions.map((tz) => (
                                <SelectItem key={tz.value} value={tz.value} className="bg-white">
                                    {tz.label}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    {getErrorMessage(1) && (
                        <p className="text-red-400 text-sm">{getErrorMessage(1)}</p>
                    )}
                </div>
            )
        },
        {
            title: "What time do you wake up, what time do you go to bed?",
            component: (
                <div className="space-y-6">
                    <div className="space-y-2">
                        <Label className="text-white/90">Wake up time</Label>
                        <div className="flex items-center space-x-2">
                            <Clock className="text-white/60" />
                            <Select
                                value={formData.wakeTime}
                                onValueChange={(value) => setFormData({ ...formData, wakeTime: value })}
                            >
                                <SelectTrigger className="w-full bg-white/90 border-0">
                                    <SelectValue>
                                        {formatTime(formData.wakeTime)}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {timeOptions.map((time) => (
                                        <SelectItem key={`wake-${time}`} value={time} className="bg-white">
                                            {formatTime(time)}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div className="space-y-2">
                        <Label className="text-white/90">Bedtime</Label>
                        <div className="flex items-center space-x-2">
                            <Clock className="text-white/60" />
                            <Select
                                value={formData.bedTime}
                                onValueChange={(value) => setFormData({ ...formData, bedTime: value })}
                            >
                                <SelectTrigger className="w-full bg-white/90 border-0">
                                    <SelectValue>
                                        {formatTime(formData.bedTime)}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    {timeOptions.map((time) => (
                                        <SelectItem key={`bed-${time}`} value={time} className="bg-white">
                                            {formatTime(time)}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    {getErrorMessage(2) && (
                        <p className="text-red-400 text-sm">{getErrorMessage(2)}</p>
                    )}
                </div>
            )
        },
        // {
        //     title: "How would you like to receive messages?",
        //     component: (
        //         <div className="space-y-6">
        //             <div className="space-y-4">
        //                 <div className="flex items-center justify-between">
        //                     <Label className="text-white/90">
        //                         <input
        //                             type="checkbox"
        //                             checked={formData.useIMessage}
        //                             onChange={(e) => handleIMessageChange(e.target.checked)}
        //                             className="mr-2"
        //                         />
        //                         Enable iMessage
        //                     </Label>
        //                 </div>
        //                 {formData.useIMessage && (
        //                     <div className="space-y-2">
        //                         <Label className="text-white/90">Phone number</Label>
        //                         <Input
        //                             type="tel"
        //                             placeholder="(555) 555-5555"
        //                             value={formData.phone}
        //                             onChange={(e) => {
        //                                 const formatted = formatPhoneNumber(e.target.value);
        //                                 if (formatted !== undefined) {
        //                                     setFormData({ ...formData, phone: formatted });
        //                                 }
        //                             }}
        //                             onKeyDown={handleKeyPress}  // Add this line
        //                             className={`bg-white/90 border-0 ${
        //                                 formData.phone && !isValidPhone(formData.phone) 
        //                                     ? 'border-2 border-red-500' 
        //                                     : ''
        //                             }`}
        //                         />
        //                     </div>
        //                 )}
        //                 <div className="flex items-center justify-between">
        //                     <Label className="text-white/90">
        //                         <input
        //                             type="checkbox"
        //                             checked={formData.useTelegram}
        //                             onChange={(e) => handleTelegramChange(e.target.checked)}
        //                             className="mr-2"
        //                         />
        //                         Enable Telegram
        //                     </Label>
        //                 </div>
        //                 {formData.useIMessage && formData.useTelegram && <MessagingPreferenceSelector />}
        //                 {getErrorMessage(3) && (
        //                     <p className="text-red-400 text-sm">{getErrorMessage(3)}</p>
        //                 )}
        //             </div>
        //         </div>
        //     )
        // },
    ];

    // Add conditional Telegram connection step
    // if (formData.useTelegram) {
    baseSteps.push({
        title: "Connect Your Telegram Account",
        component: (
            <div className="space-y-6">
                <div className="text-center">
                    <p className="text-white/90 mb-6">
                        We highly recommend connecting your Telegram account for the best experience.
                        This allows us to send you personalized messages throughout the day.
                    </p>
                    
                    <Button
                        onClick={handleTelegramConnect}
                        className="w-full mb-6 bg-blue-600 hover:bg-blue-700 text-white"
                        disabled={isPolling || formData.telegramConnected}
                    >
                        {isPolling ? (
                            <div className="flex items-center space-x-2">
                                <Loader2 className="animate-spin" />
                                <span>Connecting...</span>
                            </div>
                        ) : formData.telegramConnected ? (
                            <div className="flex items-center justify-center space-x-2">
                                <svg 
                                    className="w-5 h-5" 
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M5 13l4 4L19 7" 
                                    />
                                </svg>
                                <span>Connected!</span>
                            </div>
                        ) : (
                            "Connect Telegram"
                        )}
                    </Button>

                    <div className="space-y-4">
                        <p className="text-sm text-white/70">
                            Don't have Telegram?{' '}
                            <a 
                                href="https://telegram.org/" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-blue-400 hover:text-blue-300"
                            >
                                Download it here
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        )
    });
    // }

    // Add Terms step as the final step
    baseSteps.push({
        title: "Terms and Conditions",
        component: (
            <div className="space-y-6">
                <div className="bg-white/10 rounded-lg p-4 max-h-[300px] overflow-y-auto">
                    <div className="prose prose-sm prose-invert">
                        <h3 className="text-lg font-semibold text-white/90">End User License Agreement</h3>
                        <p className="text-white/80 text-sm leading-relaxed">
                            By using this service, you agree to the following terms and conditions:
                        </p>
                        <ol className="text-sm text-white/70 list-decimal pl-5 space-y-2">
                            <li>Your personal information will be handled according to our Privacy Policy.</li>
                            <li>Messages will be sent according to your selected preferences and timezone.</li>
                            <li>You can modify your notification preferences or opt-out at any time.</li>
                            <li>Message frequency will be based on your indicated schedule.</li>
                        </ol>
                        <p className="text-white/80 text-sm mt-4">
                            For the complete terms of service and privacy policy, please visit our website.
                        </p>
                    </div>
                </div>
                
                <label className="flex items-center space-x-3 text-white/90 cursor-pointer group">
                    <div className="relative">
                        <input
                            type="checkbox"
                            className="peer sr-only"
                            checked={formData.acceptedTerms}
                            onChange={(e) => setFormData({ ...formData, acceptedTerms: e.target.checked })}
                        />
                        <div className="h-6 w-6 border-2 border-white/20 rounded bg-white/5 peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-all group-hover:border-white/40" />
                        {formData.acceptedTerms && (
                            <svg 
                                className="absolute top-1 left-1 text-white" 
                                width="16" 
                                height="16" 
                                viewBox="0 0 16 16" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path 
                                    d="M13.5 4.5L6.5 11.5L2.5 7.5" 
                                    stroke="currentColor" 
                                    strokeWidth="2" 
                                    strokeLinecap="round" 
                                    strokeLinejoin="round"
                                />
                            </svg>
                        )}
                    </div>
                    <span className="text-sm">
                        I accept the Terms and Conditions and Privacy Policy
                    </span>
                </label>
                {getErrorMessage(6) && (
                    <p className="text-red-400 text-sm">{getErrorMessage(6)}</p>
                )}
            </div>
        )
    });

    baseSteps.push({
        title: "What topics interest you?",
        component: (
            <CategorySelection 
                formData={formData}
                setFormData={setFormData}
            />
        )
    })

    const steps = baseSteps;


    
    const [timeOfDay, setTimeOfDay] = useState('day');


    // Time of day effect
useEffect(() => {
  const updateTimeOfDay = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) setTimeOfDay('dawn');
    else if (hour >= 12 && hour < 17) setTimeOfDay('day');
    else if (hour >= 17 && hour < 20) setTimeOfDay('dusk');
    else setTimeOfDay('night');
  };
  
  updateTimeOfDay();
  const interval = setInterval(updateTimeOfDay, 60000);
  return () => clearInterval(interval);
}, []);


    const getTimeBasedColors = () => {
        switch(timeOfDay) {
          case 'dawn':
            return {
              skyTop: '#0B1026',
              skyMiddle: '#5B1E5F',
              skyBottom: '#C7385C',
              horizon: '#4ade80',
              grid: 'rgba(74, 222, 128, 0.4)', // Reduced grid opacity
              glow: '#FB923C'
            };
          case 'day':
            return {
              skyTop: '#1a365d',
              skyMiddle: '#2c5282',
              skyBottom: '#4299e1',
              horizon: '#63b3ed',
              grid: 'rgba(99, 179, 237, 0.4)',
              glow: '#63b3ed'
            };
          case 'dusk':
            return {
              skyTop: '#1a1a2e',
              skyMiddle: '#4a1e3d',
              skyBottom: '#ff6b6b',
              horizon: '#fb8c00',
              grid: 'rgba(251, 140, 0, 0.4)',
              glow: '#fb8c00'
            };
          case 'night':
            return {
              skyTop: '#0B1026',
              skyMiddle: '#1f1135',
              skyBottom: '#441831',
              horizon: '#4ade80',
              grid: 'rgba(74, 222, 128, 0.4)',
              glow: '#4ade80'
            };
        }
      };
    
      const colors = getTimeBasedColors();


    if (isLoading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-blue-900 to-purple-700 flex items-center justify-center">
                <div className="flex items-center space-x-2 text-white">
                    <Loader2 className="animate-spin" />
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gradient-to-b bg-black bg-opacity-95 flex items-center justify-center px-4">

            <Card className="w-full max-w-md bg-blue-900/40 border-0 shadow-xl">
                <div className="p-6">
                    <div className="mb-6">
                        <div className="flex justify-between mb-8">
                            {steps.map((_, index) => (
                                <div
                                    key={index}
                                    className={`h-1 flex-1 mx-1 rounded ${
                                        index <= step ? 'bg-blue-400' : 'bg-white/20'
                                    }`}
                                />
                            ))}
                        </div>
                        <h2 className="text-2xl font-bold mb-6 text-white">{steps[step].title}</h2>
                    </div>
                    
                    {steps[step].component}

                    <div className="flex justify-between mt-8">
                        <Button
                            variant="outline"
                            onClick={() => setStep(step - 1)}
                            disabled={step === 0}
                            className="bg-transparent text-white border-white/20 hover:bg-white/10"
                        >
                            Previous
                        </Button>
                        <Button
                            onClick={handleNext}
                            disabled={!isStepValid(step)}
                            className={`
                                ${!isStepValid(step) 
                                    ? 'bg-white/5 text-white/50 cursor-not-allowed' 
                                    : 'bg-white/10 text-white hover:bg-white/20'}
                            `}
                        >
                            {step === steps.length - 1 ? 'Complete' : 'Next'}
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default OnboardingFlow;