import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const STRIPE_CHECKOUT_URL = 'https://checkout.activatedhuman.earth/b/4gw7sCeDlaOY8y4144';

function LoginPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/check-subscription" />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b bg-black bg-opacity-100 text-white">
      <div className="w-full flex justify-center pt-8">
        <img
          src="/images/Activated-Human-Logo-Horizontal.png" 
          alt="Logo"
          className="h-24 w-auto"
        />
      </div>

      <main className="flex-grow flex flex-col justify-center items-center px-4 py-8">
        <motion.div
          className="relative flex items-center justify-center overflow-hidden rounded-full"
          animate={{
            width: 200,
            height: 200,
            marginBottom: 32,
          }}
          transition={{ duration: 0.3 }}
        >
          <motion.img
            src="/images/blue_idle.webp"
            alt="AI Oracle"
            className="absolute w-full h-full object-cover"
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
        <h1 className="text-4xl md:text-5xl font-bold text-center mb-4">
          Meet <span className="text-blue-400">Blue</span>, Your AI Life Managing Assistant
        </h1>
        <p className="text-xl text-center mb-8 max-w-2xl">
          Elevate your life and empower your future with personalized AI assistance that adapts to your unique rhythm and aspirations.
        </p>
        <div className="flex space-x-4">
          <button onClick={() => loginWithRedirect()} className="bg-blue-600 text-white py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300">
            Log In
          </button>
          <a 
            href="https://activatedhuman.earth" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="bg-transparent border-2 border-blue-600 text-white py-2 px-6 rounded-full text-lg hover:bg-blue-600/10 transition duration-300"
          >
            Learn More
          </a>
        </div>
      </main>
    </div>
  );
}

export default LoginPage;