import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import socketIOClient from "socket.io-client";
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from "../ui/button";
import { MessageSquare, Mic, Camera } from "lucide-react";
import AnimatedLogo from './AnimatedLogo';
import ChatInterface from './ChatInterface';
import UserReadout from './UserReadout';
import apiClient from '../../apiClient';

const SOCKET_IO_ENDPOINT = "https://a.life.activatedhuman.earth";

const Portal = () => {
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const isInitializingRef = useRef(false);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isCheckingOnboarding, setIsCheckingOnboarding] = useState(true);
  const fileInputRef = useRef(null);
  const audioRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingTimerRef = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);

  const [view, setView] = useState('home');
  const [insightsExpanded, setInsightsExpanded] = useState(false);
  const [insights, setInsights] = useState(null);
  const [inputText, setInputText] = useState('');
  const [threads, setThreads] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [timeOfDay, setTimeOfDay] = useState('day');



  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (!isAuthenticated) return;
      
      try {
        const response = await apiClient.get('/portal/onboarding-status');
        if (!response.data.isOnboarded) {
          navigate('/onboarding');
          return;
        }
      } catch (error) {
        console.error('Error checking onboarding status:', error);
        // Optionally handle error case
        // navigate('/onboarding');
      } finally {
        setIsCheckingOnboarding(false);
      }
    };

    checkOnboardingStatus();
  }, [isAuthenticated, navigate]);


  const toggleInsights = () => {
    setInsightsExpanded(prev => !prev);
  };

  // Restore socket initialization
  const initializeSocket = useCallback(async () => {
    if (socketRef.current || isInitializingRef.current) return;
    isInitializingRef.current = true;

    try {
      const newSocket = socketIOClient(SOCKET_IO_ENDPOINT, {
        path: '/api/socket.io',
        withCredentials: true,
        transports: ['websocket']
      });

      newSocket.on("connect", async () => {
        console.log("Connected to server");
        try {
          const token = await getAccessTokenSilently();
          newSocket.emit('announce', { token });
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      });

      socketRef.current = newSocket;
    } catch (error) {
      console.error("Error initializing socket:", error);
    } finally {
      isInitializingRef.current = false;
    }
  }, [getAccessTokenSilently]);


  useEffect(() => {
    if (isAuthenticated && !socketRef.current) {
      initializeSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [isAuthenticated, initializeSocket]);


  // Time of day effect
  useEffect(() => {
    const updateTimeOfDay = () => {
      const hour = new Date().getHours();
      if (hour >= 5 && hour < 12) setTimeOfDay('dawn');
      else if (hour >= 12 && hour < 17) setTimeOfDay('day');
      else if (hour >= 17 && hour < 20) setTimeOfDay('dusk');
      else setTimeOfDay('night');
    };
    
    updateTimeOfDay();
    const interval = setInterval(updateTimeOfDay, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchThreads();
    }
  }, [isAuthenticated]);
  
  const fetchThreads = async () => {
    try {
      const response = await apiClient.get('/portal/threads');
      setThreads(response.data);
      if (response.data.length > 0) {
        const todayThread = response.data.find(thread => isToday(new Date(thread.createdAt)));
        setCurrentThreadId(todayThread ? todayThread.id : response.data[0].id);
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };
  
  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const handleSendMessage = async (message, type = 'text', attachment = null) => {
    if (!currentThreadId) {
      console.error('No current thread available');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('type', type);
      
      if (type === 'text') {
        formData.append('text', message);
      } else if (type === 'audio') {
        formData.append('audio', message);
      } else if (type === 'photo') {
        formData.append('photo', message);
      }

      if (attachment) {
        formData.append('attachment', attachment);
      }

      setMessages(prev => [...prev, {
        role: 'user',
        content: [{
          type: type,
          content: type === 'text' ? message : URL.createObjectURL(message)
        }]
      }]);

      // Trigger logo animation before view change
      setIsLogoAnimated(true);
      // Small delay to allow animation to start
      await new Promise(resolve => setTimeout(resolve, 50));
      setView('conversation');

      const response = await apiClient.post(
        `/portal/threads/${currentThreadId}/messages`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response.data) {
        setMessages(prev => [...prev, {
          id: response.data.message_id,
          role: 'assistant',
          content: 'Thinking...',
          isPlaceholder: true
        }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Restore audio recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/wav' });
        handleSendMessage(blob, 'audio');
      };

      recorder.start();
      audioRecorderRef.current = recorder;
      setIsRecording(true);

      // Start timer
      setRecordingTime(0);
      recordingTimerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (audioRecorderRef.current && isRecording) {
      audioRecorderRef.current.stop();
      audioRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
      clearInterval(recordingTimerRef.current);
    }
  };

  // Restore photo handling
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleSendMessage(file, 'photo');
    }
  };

  const getTimeBasedColors = () => {
    switch(timeOfDay) {
      case 'dawn':
        return {
          skyTop: '#0B1026',
          skyMiddle: '#5B1E5F',
          skyBottom: '#C7385C',
          horizon: '#4ade80',
          grid: 'rgba(74, 222, 128, 0.4)', // Reduced grid opacity
          glow: '#FB923C'
        };
      case 'day':
        return {
          skyTop: '#1a365d',
          skyMiddle: '#2c5282',
          skyBottom: '#4299e1',
          horizon: '#63b3ed',
          grid: 'rgba(99, 179, 237, 0.4)',
          glow: '#63b3ed'
        };
      case 'dusk':
        return {
          skyTop: '#1a1a2e',
          skyMiddle: '#4a1e3d',
          skyBottom: '#ff6b6b',
          horizon: '#fb8c00',
          grid: 'rgba(251, 140, 0, 0.4)',
          glow: '#fb8c00'
        };
      case 'night':
        return {
          skyTop: '#0B1026',
          skyMiddle: '#1f1135',
          skyBottom: '#441831',
          horizon: '#4ade80',
          grid: 'rgba(74, 222, 128, 0.4)',
          glow: '#4ade80'
        };
    }
  };

  const colors = getTimeBasedColors();

  useEffect(() => {
    const fetchInsights = async () => {
      if (isAuthenticated) {
        try {
          const response = await apiClient.get('/portal/insights');
          console.log('API Response:', response.data); // Debug log
          setInsights(response.data.insights); // Make sure we're accessing .insights from the response
        } catch (error) {
          console.error('Error fetching insights:', error);
          setInsights({});
        }
      }
    };
  
    fetchInsights();
  }, [isAuthenticated]);

  // Main render
  return (
    <div className="relative h-screen overflow-hidden bg-black">
      {/* Background gradient - static positioning */}
      <div 
        className="absolute inset-0 transition-all duration-1000"
        style={{
          backgroundImage: `linear-gradient(180deg, 
            ${colors.skyTop} 0%,
            ${colors.skyMiddle} 15%,
            ${colors.skyBottom} 17.9%,
            ${colors.skyBottom} 18%,
            rgba(0,0,0,1) 18.1%)`
        }}
      />

      {/* Stars */}
      <div className="absolute inset-0 opacity-30">
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute w-px h-px bg-white rounded-full animate-twinkle"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 60}%`,
              animationDelay: `${Math.random() * 2}s`
            }}
          />
        ))}
      </div>

      {/* Grid Surface */}
      <div className="absolute inset-0" style={{ perspective: '500px' }}>
        <div 
          className="absolute w-full h-[150vh]"
          style={{
            bottom: '-10vh',
            backgroundImage: `
              linear-gradient(90deg, ${colors.grid} 1px, transparent 1px),
              linear-gradient(0deg, ${colors.grid} 1px, transparent 1px)
            `,
            backgroundSize: '100px 100px',
            transform: 'rotateX(60deg)',
            transformOrigin: 'bottom',
            backgroundPosition: 'center',
            maskImage: 'linear-gradient(transparent 0%, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0.1) 60%)',
            WebkitMaskImage: 'linear-gradient(transparent 0%, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0.1) 60%)'
          }}
        />
      </div>

      {/* Content */}
      <div className="relative h-full flex flex-col items-center">
        {/* Logo and Horizon - Always visible */}
          <motion.div 
            className="absolute w-full flex justify-center"  // Added flex and justify-center
            initial={false}
            animate={{
              top: '3%',
              scale: 0.75
            }}
            transition={{ duration: 0.5 }}
          >
            {/* Sun Glow - Now centered */}
            <div 
              className="absolute left-1/2 -translate-x-1/2 rounded-full blur-3xl"
              style={{
                backgroundImage: `radial-gradient(circle, ${colors.glow} 0%, transparent 70%)`,
                transform: 'translate(-50%) scale(2)',  // Updated transform to maintain center
                opacity: 0.6,
                width: '300px',  // Added explicit width
                height: '300px'  // Added explicit height
              }}
            />
            
            {/* Horizon Line - Aligned with gradient transition */}
            <div 
              className="absolute left-0 right-0 h-[2px]"
              style={{
                top: '47.5%',  // Aligned with the 30% gradient transition point
                width: '200vw',
                left: '-50vw',
                backgroundImage: `linear-gradient(90deg, 
                  transparent 0%,
                  ${colors.horizon} 20%,
                  ${colors.horizon} 80%,
                  transparent 100%)`,
                boxShadow: `0 0 30px ${colors.horizon}`,
                opacity: 0.8
              }}
            />
            
            <div className="relative">  {/* Added container for logo */}
              <AnimatedLogo colors={colors} />
            </div>
          </motion.div>

        <AnimatePresence mode="wait">
        {view === 'home' && (
          <motion.div 
            className="relative h-full w-full flex flex-col items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="absolute w-full"
              style={{ top: 'calc(25% + 100px)' }}
            >
              <UserReadout 
                insights={insights} // You'll need to fetch this from your API
                colors={colors}
              />
            </motion.div>
          </motion.div>
        )}

          {view === 'conversation' && (
            <motion.div 
              className="absolute w-full px-[25%] bottom-0"
              style={{ top: '25%' }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.3 }}
            >
              <ChatInterface
                threadId={currentThreadId}
                socket={socketRef.current}
                messages={messages}
                setMessages={setMessages}
                onSendMessage={handleSendMessage}
                onCollapse={() => {
                  setView('home');
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Insights Button */}
      {/* <Button 
        onClick={toggleInsights}
        className="fixed bottom-6 right-6 bg-white/10 hover:bg-white/20 text-white backdrop-blur-md rounded-full px-6 py-3 z-50"
      >
        {insightsExpanded ? 'Hide Insights' : 'Show Insights'}
      </Button> */}

      {/* Insights Panel */}
      <AnimatePresence>
        {insightsExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="fixed bottom-0 left-0 right-0 bg-black/50 backdrop-blur-md border-t border-white/20"
          >
            <div className="p-6">
              <h2 className="text-xl font-light text-white mb-4">Insights</h2>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Portal;