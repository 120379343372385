import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AnimatedLogo = ({ colors }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  
  const images = [
    '/images/metatron_2.webp',
    '/images/metatron_1.webp',
    '/images/metatron_3.webp',
    '/images/metatron_1.webp'
  ];

  const handleAnimationComplete = () => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  return (
    <div className="relative w-72 h-72">
      <div 
        className="absolute inset-0 rounded-full blur-3xl"
        style={{
          background: `radial-gradient(circle, ${colors.glow} 0%, transparent 70%)`,
          opacity: 0.3
        }}
      />
      <div className="relative rounded-full overflow-hidden">
        <AnimatePresence mode="wait">
          <motion.img
            key={currentImage}
            src={images[currentImage]}
            alt="Life Framework"
            className="w-full h-full object-cover"
            initial={isFirstLoad ? { opacity: 1 } : { opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            onAnimationComplete={handleAnimationComplete}
          />
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AnimatedLogo;