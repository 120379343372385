import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Badge } from "../ui/badge";
import { ScrollArea } from "../ui/scroll-area";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { 
  Brain, 
  Heart, 
  Building2, 
  Gem, 
  TreePine,
  Wallet,
  Home,
  Lightbulb,
  Users,
  Compass
} from 'lucide-react';

const UserReadout = ({ insights, colors }) => {
  const [selectedCategory, setSelectedCategory] = useState('foundational');
  
  // Add debug logging
  console.log('Received insights:', insights);

  const categories = {
    foundational: {
      title: "Foundational Stability",
      color: "text-emerald-400",
      icon: TreePine,
      dimensions: ['physical_wellbeing', 'financial_material', 'environmental_harmony']
    },
    personal: {
      title: "Personal Development",
      color: "text-blue-400",
      icon: Brain,
      dimensions: ['intellectual_growth', 'emotional_intelligence', 'mental_wellbeing']
    },
    social: {
      title: "Social Sphere",
      color: "text-purple-400",
      icon: Users,
      dimensions: ['relationships_community', 'occupational_purpose']
    },
    higher: {
      title: "Higher Self",
      color: "text-amber-400",
      icon: Compass,
      dimensions: ['spiritual_connection', 'self_actualization']
    }
  };

  const dimensionIcons = {
    physical_wellbeing: Heart,
    financial_material: Wallet,
    environmental_harmony: Home,
    intellectual_growth: Lightbulb,
    emotional_intelligence: Heart,
    mental_wellbeing: Brain,
    relationships_community: Users,
    occupational_purpose: Building2,
    spiritual_connection: Compass,
    self_actualization: Gem
  };

  const dimensionTitles = {
    physical_wellbeing: "Physical Wellbeing",
    financial_material: "Financial & Material",
    environmental_harmony: "Environmental Harmony",
    intellectual_growth: "Intellectual Growth",
    emotional_intelligence: "Emotional Intelligence",
    mental_wellbeing: "Mental Wellbeing",
    relationships_community: "Relationships & Community",
    occupational_purpose: "Occupational Purpose",
    spiritual_connection: "Spiritual Connection",
    self_actualization: "Self-Actualization"
  };

  // Add debug check for insights structure
  const hasInsights = insights && Object.keys(insights).length > 0;
  console.log('Has insights:', hasInsights);
  if (hasInsights) {
    console.log('Sample insight:', insights[Object.keys(insights)[0]]);
  }

  return (
    <div className="w-full max-w-5xl mx-auto px-4 pb-20">
      {/* Add debug display */}
      {!hasInsights && (
        <div className="text-white/60 mb-4">
          Debug: No insights available. Raw data: {JSON.stringify(insights)}
        </div>
      )}
      
      <div className="w-full max-w-5xl mx-auto px-4 h-[calc(100vh-300px)]"> 
        <Tabs 
            defaultValue="foundational" 
            className="w-full h-full flex flex-col" 
            value={selectedCategory}
            onValueChange={setSelectedCategory}
        >
            <div className="sticky top-0 bg-black/20 backdrop-blur-md py-4 z-10">
            <TabsList className="grid grid-cols-4 w-full">
            {Object.entries(categories).map(([key, category]) => {
              const Icon = category.icon;
              return (
                <TabsTrigger 
                  key={key} 
                  value={key}
                  className={`flex flex-col items-center gap-2 p-4 ${category.color}`}
                >
                  <Icon className="w-6 h-6" />
                  <span className="text-xs font-light">{category.title}</span>
                </TabsTrigger>
              );
            })}
          </TabsList>
        </div>

        <ScrollArea className="flex-1 mt-6 pr-4 pb-20">  {/* Changed to flex-1 and added bottom padding */}
            {Object.entries(categories).map(([key, category]) => (
                <TabsContent 
                key={key} 
                value={key} 
                className="mt-0 min-h-full"  
                >
                <div className="grid grid-cols-1 gap-6 pb-20">  {/* Added bottom padding */}
                {category.dimensions.map(dimension => {
                  const insight = insights?.[dimension];
                  // Add debug logging for each dimension
                  console.log(`Checking dimension ${dimension}:`, insight);
                  
                  const Icon = dimensionIcons[dimension];
                  
                  return (
                    <motion.div
                        key={dimension}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        className="rounded-lg border border-white/20 bg-black/40 backdrop-blur-xl overflow-hidden hover:border-white/30 transition-colors"
                        >
                        <div className="p-8">  {/* Increased padding */}
                            <div className="flex items-center gap-4 mb-6">  {/* Increased spacing */}
                            <Icon className={`w-8 h-8 ${category.color}`} />  {/* Larger icon */}
                            <h3 className="text-xl font-medium text-white tracking-wide">
                                {dimensionTitles[dimension]}
                            </h3>
                            </div>
                        
                        {insight ? (
                        <div className="space-y-6">
                            {insight.current_state && (
                            <div>
                                <Badge 
                                variant="outline" 
                                className="mb-2 text-emerald-400 border-emerald-400/30 bg-emerald-400/10"
                                >
                                Current State
                                </Badge>
                                <p className="text-white/90 text-lg leading-relaxed mt-2">
                                {insight.current_state}
                                </p>
                            </div>
                            )}
                            
                            {insight.patterns && (
                            <div>
                                <Badge 
                                variant="outline" 
                                className="mb-2 text-blue-400 border-blue-400/30 bg-blue-400/10"
                                >
                                Patterns
                                </Badge>
                                <p className="text-white/90 text-lg leading-relaxed mt-2">
                                {insight.patterns}
                                </p>
                            </div>
                            )}
                            
                            {insight.growth_edges && (
                            <div>
                                <Badge 
                                variant="outline" 
                                className="mb-2 text-purple-400 border-purple-400/30 bg-purple-400/10"
                                >
                                Growth Edges
                                </Badge>
                                <p className="text-white/90 text-lg leading-relaxed mt-2">
                                {insight.growth_edges}
                                </p>
                            </div>
                            )}
                            
                            {insight.resources_strengths && (
                            <div>
                                <Badge 
                                variant="outline" 
                                className="mb-2 text-amber-400 border-amber-400/30 bg-amber-400/10"
                                >
                                Resources & Strengths
                                </Badge>
                                <p className="text-white/90 text-lg leading-relaxed mt-2">
                                {insight.resources_strengths}
                                </p>
                            </div>
                            )}
                        </div>
                        ) : (
                        <p className="text-white/60 italic text-lg">
                            No insights available for {dimensionTitles[dimension]}
                        </p>
                        )}

                      </div>
                    </motion.div>
                  );
                })}
              </div>
            </TabsContent>
          ))}
        </ScrollArea>
      </Tabs>
    </div>
    </div>
  );
};

export default UserReadout;