import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Switch } from "../ui/switch";
import { Button } from "../ui/button";
// import { useToast } from "../ui/use-toast";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { Clock, Save, Loader2 } from 'lucide-react';
import apiClient from '../../apiClient';

const ProfileSettings = () => {
    // const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [settings, setSettings] = useState({
        name: '',
        timezone: '',
        schedule: {
            wakeTime: '07:00',
            bedTime: '22:00'
        },
        messaging: {
            useIMessage: false,
            useTelegram: false,
            phone: '',
            preferredMethod: 'telegram'
        },
        insightPreferences: {}
    });
    
    // Keep track of original settings for detecting changes
    const [originalSettings, setOriginalSettings] = useState(null);

    // Time options for wake/bed times
    const timeOptions = generateTimeOptions();
    
    // Timezone options
    const timezoneOptions = [
        { value: 'America/New_York', label: 'Eastern Time (New York)' },
        { value: 'America/Chicago', label: 'Central Time (Chicago)' },
        { value: 'America/Denver', label: 'Mountain Time (Denver)' },
        { value: 'America/Los_Angeles', label: 'Pacific Time (Los Angeles)' },
        { value: 'America/Anchorage', label: 'Alaska Time (Anchorage)' },
        { value: 'Pacific/Honolulu', label: 'Hawaii Time (Honolulu)' }
    ];

    // Fetch settings on mount
    useEffect(() => {
        fetchSettings();
    }, []);

    // Check for changes
    useEffect(() => {
        if (originalSettings) {
            const hasChanges = JSON.stringify(settings) !== JSON.stringify(originalSettings);
            setHasChanges(hasChanges);
        }
    }, [settings, originalSettings]);

    const fetchSettings = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient.get('/portal/profile-settings');
            setSettings(response.data);
            setOriginalSettings(response.data);
        } catch (error) {
            console.error('Error fetching settings:', error);
            // toast({
            //     title: "Error",
            //     description: "Failed to load settings",
            //     variant: "destructive"
            // });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);
            
            // Only send changed fields
            const changedSettings = {};
            if (settings.name !== originalSettings.name) changedSettings.name = settings.name;
            if (settings.timezone !== originalSettings.timezone) changedSettings.timezone = settings.timezone;
            if (JSON.stringify(settings.schedule) !== JSON.stringify(originalSettings.schedule)) {
                changedSettings.schedule = settings.schedule;
            }
            if (JSON.stringify(settings.messaging) !== JSON.stringify(originalSettings.messaging)) {
                changedSettings.messaging = settings.messaging;
            }
            if (JSON.stringify(settings.insightPreferences) !== JSON.stringify(originalSettings.insightPreferences)) {
                changedSettings.insightPreferences = settings.insightPreferences;
            }

            const response = await apiClient.patch('/portal/profile-settings', changedSettings);
            setSettings(response.data);
            setOriginalSettings(response.data);
            setHasChanges(false);

            // toast({
            //     title: "Success",
            //     description: "Settings updated successfully"
            // });
        } catch (error) {
            console.error('Error saving settings:', error);
            // toast({
            //     title: "Error",
            //     description: "Failed to save settings",
            //     variant: "destructive"
            // });
        } finally {
            setIsSaving(false);
        }
    };

    function generateTimeOptions() {
        const options = [];
        for (let i = 5; i <= 23; i++) {
            options.push(`${i.toString().padStart(2, '0')}:00`);
            options.push(`${i.toString().padStart(2, '0')}:30`);
        }
        options.push('00:00');
        return options;
    }

    const formatTime = (time) => {
        const [hours, minutes] = time.split(':');
        const date = new Date(2000, 0, 1, hours, minutes);
        return date.toLocaleTimeString([], { 
            hour: 'numeric', 
            minute: '2-digit',
            hour12: true 
        });
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loader2 className="animate-spin text-white/60" />
            </div>
        );
    }

    return (
        <div className="container max-w-4xl mx-auto p-6">
            <Card className="bg-black/40 border-white/10">
                <CardHeader className="flex flex-row items-center justify-between">
                    <CardTitle className="text-2xl font-light text-white">Profile Settings</CardTitle>
                    <Button
                        onClick={handleSave}
                        disabled={isSaving || !hasChanges}
                        className="bg-white/10 hover:bg-white/20 text-white"
                    >
                        {isSaving ? (
                            <>
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                Saving...
                            </>
                        ) : (
                            <>
                                <Save className="mr-2 h-4 w-4" />
                                Save Changes
                            </>
                        )}
                    </Button>
                </CardHeader>
                <CardContent>
                    <Tabs defaultValue="general" className="space-y-6">
                        <TabsList className="bg-white/10">
                            <TabsTrigger value="general">General</TabsTrigger>
                            <TabsTrigger value="schedule">Schedule</TabsTrigger>
                            <TabsTrigger value="messaging">Messaging</TabsTrigger>
                            <TabsTrigger value="insights">Insights</TabsTrigger>
                        </TabsList>

                        <TabsContent value="general" className="space-y-6">
                            <div className="space-y-4">
                                <div>
                                    <Label className="text-white/90">Name</Label>
                                    <Input
                                        value={settings.name}
                                        onChange={(e) => setSettings({
                                            ...settings,
                                            name: e.target.value
                                        })}
                                        className="bg-white/10 border-white/20 text-white"
                                    />
                                </div>
                                <div>
                                    <Label className="text-white/90">Timezone</Label>
                                    <Select
                                        value={settings.timezone}
                                        onValueChange={(value) => setSettings({
                                            ...settings,
                                            timezone: value
                                        })}
                                    >
                                        <SelectTrigger className="bg-white/10 border-white/20 text-white">
                                            <SelectValue placeholder="Select timezone" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {timezoneOptions.map((tz) => (
                                                <SelectItem key={tz.value} value={tz.value}>
                                                    {tz.label}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </TabsContent>

                        <TabsContent value="schedule" className="space-y-6">
                            <div className="space-y-4">
                                <div>
                                    <Label className="text-white/90">Wake up time</Label>
                                    <div className="flex items-center space-x-2">
                                        <Clock className="text-white/60" />
                                        <Select
                                            value={settings.schedule.wakeTime}
                                            onValueChange={(value) => setSettings({
                                                ...settings,
                                                schedule: {
                                                    ...settings.schedule,
                                                    wakeTime: value
                                                }
                                            })}
                                        >
                                            <SelectTrigger className="bg-white/10 border-white/20 text-white">
                                                <SelectValue>
                                                    {formatTime(settings.schedule.wakeTime)}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                {timeOptions.map((time) => (
                                                    <SelectItem key={`wake-${time}`} value={time}>
                                                        {formatTime(time)}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>

                                <div>
                                    <Label className="text-white/90">Bedtime</Label>
                                    <div className="flex items-center space-x-2">
                                        <Clock className="text-white/60" />
                                        <Select
                                            value={settings.schedule.bedTime}
                                            onValueChange={(value) => setSettings({
                                                ...settings,
                                                schedule: {
                                                    ...settings.schedule,
                                                    bedTime: value
                                                }
                                            })}
                                        >
                                            <SelectTrigger className="bg-white/10 border-white/20 text-white">
                                                <SelectValue>
                                                    {formatTime(settings.schedule.bedTime)}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                {timeOptions.map((time) => (
                                                    <SelectItem key={`bed-${time}`} value={time}>
                                                        {formatTime(time)}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        </TabsContent>

                        <TabsContent value="messaging" className="space-y-6">
                            <div className="space-y-6">
                                <div className="flex items-center justify-between">
                                    <Label className="text-white/90">Telegram Messaging</Label>
                                    <Switch
                                        checked={settings.messaging.useTelegram}
                                        onCheckedChange={(checked) => setSettings({
                                            ...settings,
                                            messaging: {
                                                ...settings.messaging,
                                                useTelegram: checked,
                                                preferredMethod: checked && !settings.messaging.useIMessage ? 'telegram' : settings.messaging.preferredMethod
                                            }
                                        })}
                                    />
                                </div>

                                <div className="flex items-center justify-between">
                                    <Label className="text-white/90">iMessage</Label>
                                    <Switch
                                        checked={settings.messaging.useIMessage}
                                        onCheckedChange={(checked) => setSettings({
                                            ...settings,
                                            messaging: {
                                                ...settings.messaging,
                                                useIMessage: checked,
                                                preferredMethod: checked && !settings.messaging.useTelegram ? 'imessage' : settings.messaging.preferredMethod
                                            }
                                        })}
                                    />
                                </div>

                                {settings.messaging.useIMessage && (
                                    <div>
                                        <Label className="text-white/90">Phone Number</Label>
                                        <Input
                                            type="tel"
                                            value={settings.messaging.phone || ''}
                                            onChange={(e) => setSettings({
                                                ...settings,
                                                messaging: {
                                                    ...settings.messaging,
                                                    phone: e.target.value
                                                }
                                            })}
                                            className="bg-white/10 border-white/20 text-white"
                                            placeholder="(555) 555-5555"
                                        />
                                    </div>
                                )}

                                {settings.messaging.useIMessage && settings.messaging.useTelegram && (
                                    <div>
                                        <Label className="text-white/90">Preferred Method</Label>
                                        <Select
                                            value={settings.messaging.preferredMethod}
                                            onValueChange={(value) => setSettings({
                                                ...settings,
                                                messaging: {
                                                    ...settings.messaging,
                                                    preferredMethod: value
                                                }
                                            })}
                                        >
                                            <SelectTrigger className="bg-white/10 border-white/20 text-white">
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="imessage">iMessage</SelectItem>
                                                <SelectItem value="telegram">Telegram</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                            </div>
                        </TabsContent>

                        <TabsContent value="insights" className="space-y-6">
                            <div className="space-y-4">
                                {Object.entries(settings.insightPreferences).map(([dimension, enabled]) => (
                                    <div key={dimension} className="flex items-center justify-between">
                                        <Label className="text-white/90">
                                            {dimension.split('_').map(word => 
                                                word.charAt(0).toUpperCase() + word.slice(1)
                                            ).join(' ')}
                                        </Label>
                                        <Switch
                                            checked={enabled}
                                            onCheckedChange={(checked) => setSettings({
                                                ...settings,
                                                insightPreferences: {
                                                    ...settings.insightPreferences,
                                                    [dimension]: checked
                                                }
                                            })}
                                        />
                                    </div>
                                ))}
                            </div>
                        </TabsContent>
                    </Tabs>
                </CardContent>
            </Card>
        </div>
    );
};

export default ProfileSettings;