import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Send, Paperclip } from 'lucide-react';
import { Button } from "../ui/button";
import apiClient from '../../apiClient';

const ChatInterface = React.memo(({ threadId, setThreadId, socket, setCurrentView }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (threadId) {
      fetchMessages(threadId);
    }
  }, [threadId]);

  const addMessage = useCallback((newMessage) => {
    setMessages(prevMessages => {
      // Check for duplicate IDs
      // const isDuplicate = prevMessages.some(msg => msg.id === newMessage.id);
      // if (isDuplicate) {
      //   console.warn(`Duplicate message ID detected: ${newMessage.id}`);
      // }

      console.log('Previous messages:', prevMessages);
      const updatedMessages = [...prevMessages, newMessage];
      console.log('Updated messages:', updatedMessages);
      return updatedMessages;
    });
  }, []);

  const updateMessage = useCallback((messageId, updateFn) => {
    setMessages(prevMessages => {
      console.log('Updating message:', messageId);
      console.log('Previous messages:', prevMessages);
      const updatedMessages = prevMessages.map(msg =>
        msg.id === messageId ? updateFn(msg) : msg
      );
      console.log('Updated messages:', updatedMessages);
      return updatedMessages;
    });
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('messageResponseCreated', (message) => {
        console.log('Received message:', message);
        updateMessage(message.message_id, (msg) => ({
          ...msg,
          content: message.message_text,
          isPlaceholder: false
        }));
      });

      socket.on('token', ({ thread_id, message_id, token, is_final }) => {
        if (thread_id === threadId) {
          updateMessage(message_id, (msg) => ({
            ...msg,
            content: msg.isPlaceholder ? token : msg.content + token,
            isPlaceholder: !is_final
          }));
        }
      });
    }

    return () => {
      if (socket) {
        socket.off('messageResponseCreated');
        socket.off('token');
      }
    };
  }, [socket, threadId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const fetchMessages = useCallback(async (id) => {
    try {
      const response = await apiClient.get(`/portal/threads/${id}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, []);

  const handleSend = async () => {
    if (!inputText && !attachment) return;
  
    const formData = new FormData();
    formData.append('text', inputText);
    if (attachment) {
      formData.append('attachment', attachment);
    }
    
    let response;
    try {
      response = await apiClient.post(`/portal/threads/${threadId}/messages`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      addMessage({ 
        id: `user-${Date.now()}`,
        role: 'user', 
        content: inputText,
        attachment_url: attachment ? URL.createObjectURL(attachment) : null
      });
      
      if (response.data) {
        addMessage({ 
          id: response.data.message_id, 
          role: 'assistant', 
          content:  'Thinking...', 
          isPlaceholder: true
        });
      }
      
      setInputText('');
      setAttachment(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleAttachment = (e) => {
    const file = e.target.files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'application/pdf')) {
      setAttachment(file);
    } else {
      alert('Please select an image or PDF file');
    }
  };

  const generateSummary = async () => {
    setIsGeneratingSummary(true);
    try {
      const response = await apiClient.post(`/portal/threads/${threadId}/summary`);
      setMessages(prevMessages => [...prevMessages, response.data]);
    } catch (error) {
      console.error('Error generating summary:', error);
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const renderMessageContent = (msg) => {
    if (typeof msg.content === 'string') {
      return <p className="mb-2">{msg.content}</p>;
    } else if (Array.isArray(msg.content)) {
      return msg.content.map((item, index) => {
        if (typeof item === 'object' && item.content) {
          return <p key={index} className="mb-2">{item.content}</p>;
        }
        return null;
      });
    } else if (msg.attachment_url) {
      return <img src={msg.attachment_url} alt="Attachment" className="max-w-xs rounded-lg shadow-md" />;
    }
    return null;
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((msg, index) => (
          <div key={msg.id || index} className={`mb-4 ${msg.role === 'user' ? 'text-right' : 'text-left'}`}>
            <div className={`inline-block p-3 rounded-lg ${msg.role === 'user' ? 'bg-blue-500 text-white' : 'bg-white shadow-md'}`}>
              {renderMessageContent(msg)}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-white border-t">
        <div className="flex items-center mb-2">
          <button onClick={() => setCurrentView('dashboard')} className="mr-2 px-3 py-1 bg-gray-200 rounded-full text-sm hover:bg-gray-300 transition-colors duration-200">
            Dashboard
          </button>
            <button 
              onClick={generateSummary} 
              className="px-3 py-1 bg-green-500 text-white rounded-full text-sm hover:bg-green-600 transition-colors duration-200"
              disabled={isGeneratingSummary}
            >
              {isGeneratingSummary ? 'Generating...' : 'Generate Summary'}
            </button>
        </div>
        <div className="flex items-center">
          <input
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            className="flex-1 border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={messages.length == 0 ? "Start a new conversation..." : "Type your message..."}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleAttachment}
            accept="image/*,application/pdf"
            className="hidden"
          />
          <Button
            onClick={() => fileInputRef.current.click()}
            className="px-3 py-2 bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-200"
          >
            <Paperclip className="h-5 w-5" />
          </Button>
          <Button onClick={handleSend} className="rounded-r-lg">
            <Send className="h-5 w-5 mr-2" />
            Send
          </Button>
        </div>
        {attachment && (
          <div className="mt-2 text-sm text-gray-600">
            Attachment: {attachment.name}
            <button onClick={() => setAttachment(null)} className="ml-2 text-red-500 hover:text-red-700">
              Remove
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

export default ChatInterface;